import Vue from 'vue'
import Vant from 'vant';
import App from './App.vue'
import 'amfe-flexible'
import 'vant/lib/index.css';
import './index.less'
import router from './router';
import axios from 'axios'
import '@/assets/icon/iconfont.css'
import {
  Toast
} from 'vant';
import store from './store'
import vueEsign from 'vue-esign'
import {
  Dialog,
} from 'vant';
import { saveErrorLogger } from "@/util/api";
import { changePrice, wxConfig, is_andriod_ios } from "@/util/tool";
Vue.use(Dialog);
Vue.use(vueEsign)
Vue.use(Vant);
Vue.config.productionTip = false

setTimeout(()=>{
  require('amfe-flexible')
},700)

const errorHandler = (error)=>{
  console.error('抛出全局异常');

  let err = error.toString()
  if (store.state.appType == 'browser' && store.state.accesstoken == '' || !store.state.accesstoken) {
    saveErrorLogger(err).then(() => {
    })
    //  router.push({path: '/login'})
  }
}
Vue.config.errorHandler = errorHandler;
Vue.prototype.$throw = (error)=> errorHandler(error,this);
Vue.prototype.changePrice = changePrice
Vue.prototype.wxConfig = wxConfig
Vue.prototype.is_andriod_ios = is_andriod_ios

axios.interceptors.request.use(config => {
  console.log(config, 'config')
  Toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick: true, // 禁用背景点击
    loadingType: 'spinner',
    message: '正在加载'
  })
  const {
    accesstoken
  } = sessionStorage
  if (accesstoken) {
    config.headers['accesstoken'] = accesstoken
  } else if (store.state.accesstoken) {
    config.headers['accesstoken'] = store.state.accesstoken
  }
  return config
})

axios.interceptors.response.use(options => {
  const {
    status,
    data = {},
    statusText = '网络错误',
    headers: {
      accesstoken
    } = {},
  } = options || {}
  Toast.clear()
  if (status === 200) {
    const {
      code,
      msg,
      data: res,
      extraData
    } = data
    if (accesstoken) {
      sessionStorage.accesstoken = accesstoken
      store.state.accesstoken = accesstoken
    } else if (store.state.accesstoken) {
      sessionStorage.accesstoken = store.state.accesstoken
    }
    if (code === 0 || code === 50001) {
      return res || extraData || code
    } else {
      Toast.fail(`${msg}`);
      return Promise.reject({data, code, msg})
    }
  }
  Toast.fail(`${statusText}`);
  return Promise.reject(statusText)
},error => { // for debug
  Toast.clear()
  Toast.fail('网络错误');
  console.log(error, 'error');
})

Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份 
    "d+": this.getDate(), //日 
    "h+": this.getHours(), //小时 
    "m+": this.getMinutes(), //分 
    "s+": this.getSeconds(), //秒 
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
    "S": this.getMilliseconds() //毫秒 
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')