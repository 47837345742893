import axios from "axios";

/**
 * 获取小程序Scheme
 * @param {{ appId: string }} data
 * @returns 
 */
export const getMiniProgramScheme = (data) => {
  return axios.post(`/auth/generatescheme`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 发送认证验证码
 * @param {{ captcha: any, mobilePhone: string }} data
 * @returns 
 */
export const sendAuthCodeByMobile = (data) => {
  return axios.post('/auth/sendAuthCodeByMobile', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

export const industrySolutionDetails = (data) => {
  return axios.post(`/api/jyq-base/admin/goods/goodsDetails?id=${data.id}`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

export const goodsDetailsByParticulars = data => {  // 行业方案商品详情
	return axios.post(`/api/jyq-base/admin/goods/goodsDetailsByParticulars?id=${data.id}`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

export const getSystemInfos = data => {  // 获取系统信息
	return axios.post('/api/jyq-base/admin/systeminfo/getSystemInfos', data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

export const getMenuMap = data => {  // 官网菜单map
	return axios.post('/api/jyq-base/admin/role/getMenuMap', data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

// 获取其它信息
export const getOtherInfo = (data) => {
  return axios.post(`/api/auth/getOtherInfo?key=${data.key}`,data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

export const goodsGoodsPage = data => {  // 商品分页
	return axios.post('/api/jyq-base/admin/goods/goodsPage', data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
export const customerServiceRecord = data => {  // 官网客服记录添加
	return axios.post('/api/jyq-base/tenant/customer/customerServiceRecord', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

// 企业注册

// 注册验证码发送
export const registerSendAuthCode = (data) => {
  return axios.post('/api/auth/sendregisterTenantAuthCode', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

// 注册企业
export const registerTenant = (data) => {
  return axios.post('/api/auth/registerTenant', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

// 获取验证图片  以及token
export const captchaGet = (data) => {
  return axios.post('/api/auth/createCaptcha', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

// 滑动或者点选验证
export const captchaCheck = (data) => {
  return axios.post('/api/auth/checkCaptcha', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

// 获取scheme码
export const generatescheme = (data) => {
  return axios.post(`/api/auth/generatescheme`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

export const queryEnterpriseBusiness = (data) => {
  return axios.post(`/api/api/queryEnterpriseBusiness?word=${data.word}`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}