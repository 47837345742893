import Vue from 'vue'
import Router from "vue-router"



Vue.use(Router)

// let linkType =''
const routes = [
  {
    path:  '/',
    name:  'home',
    component: () => import('@/views/home/home.vue')
  },
  {
    path:  '/home-page',
    name:  'home-page',
    component: () => import('@/views/home/home.vue')
  },
  {
    path:  '/detail',
    name:  'detail',
    component: () => import('@/views/detail/index.vue')
  },
  {
    path:  '/guide',
    name:  'guide',
    component: () => import('@/views/guide/index.vue')
  },
]

const router = new Router({
  routes,
  mode: 'hash',
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})
router.afterEach(() => {
  window.scrollTo(0,0);
})
router.beforeEach((to, from, next) => {
  const {
    sourceType,
  } = to.query
  if(sourceType === 'miniWx') {
    sessionStorage.setItem('sourceType', 'miniWx')
  } else {
    next()
  }
})
export default router