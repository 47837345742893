import {
  doSignature
} from '@/util/api'
import axios from 'axios';
import wx from 'weixin-js-sdk';

let Base64 = require('js-base64').Base64
/**
 * 对象转formdate
 * @param {object} obj 
 * @returns 
 */
export const changeFormData = (obj = {}) => {
  const formData = new FormData()
  Object.keys(obj).forEach(v => {
    formData.append(v, obj[v])
  });
  return formData
}

/**
 * 随机数长度
 * @param {*} len 
 * @returns 
 */
const random_string = (len) => {
  len = len || 32
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var maxPos = chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}
let Trim = (str) => { // 去除前后空格
  str = String(str)
  return str ? str.replace(/(^\s*)|(\s*$)/g, '') : str || ''
}
// 金额添加千分位
const comdify = function (n) {
  if (!n) return n
  let str = n.split('.')
  let re = /\d{1,3}(?=(\d{3})+$)/g
  let n1 = str[0].replace(re, '$&,')
  // return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.00`
  return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}`
}
// 去除千分位中的‘，'
const delcommafy = function (num) {
  if (!num) return num
  num = num.toString()
  num = num.replace(/,/gi, '')
  return num
}
export const changePrice = (str = 0, type, num = 2) => { // 转换金额格式  或百分比格式
  let val = Trim(str) || ''
  val = Number(val)
  if (type == '%' && val) {
    val = (val * 100)
    val = Number(val).toFixed(num)
    val = `${val}%`
  } else if (type == 'price' && val) {
    val = comdify(delcommafy(val))
  } else if (val) {
    val = Number(val).toFixed(num).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') || 0
  }
  return val
}
/**
 * 上传
 */
export const oss = async (file) => {
  const {
    host,
    policy,
    accessid,
    signature,
    callBackUrl,
    dir
  } = await doSignature()
  const key = `${dir}/${new Date().getTime()}_${random_string(10)}signImage.png`
  const updateObj = {
    host,
    key,
    policy,
    OSSAccessKeyId: accessid,
    success_action_status: 200,
    signature,
    callback: callBackUrl,
    headers: {
      Expires: new Date(1000).toUTCString(),
      'x-oss-forbid-overwrite': true
    },
    file
  }
  const {
    size,
    object,
    mimeType
  } = await axios.post(host, changeFormData(updateObj), {
    headers: {
      ...updateObj.headers,
      accesstoken: sessionStorage.accesstoken
    }
  })
  return Promise.resolve({
    size,
    object,
    mimeType,
    host
  })
}

/**
 * 预览地址
 */
export const ossUrl = (res) => {
  const url = encodeURIComponent(Base64.encode(res))
  if (process.env.NODE_ENV === 'development') {
    location.href = (`https://emtest.tortoisecloud.com:48081/preview/onlinePreview?url=${url}`)
  } else {
    location.href = (`/preview/onlinePreview?url=${url}`)
  }
}

/**
 * 文件下载
 */
export const downloadFile = (res, type, filename) => {
  // 创建blob对象，将二进制数据封装为BLOB对象
  const blob = new Blob([res], {
    // 如何后端没返回下载文件类型，则需要手动设置：type: 'application/pdf;chartset=UTF-8' 表示下载文档为pdf，如果是word则设置为msword，excel为excel
    type: type
  })
  const a = document.createElement('a')
  // 兼容webkix浏览器，处理webkit浏览器中herf自动添加blob前缀，默认在浏览器打开而不是下载
  const URL = window.URL || window.webkitURL
  // 根据blob对象创建URL 对象，生成本地URL
  const herf = URL.createObjectURL(blob)
  // 下载链接
  a.href = herf
  // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  // 在内存中移除URL 对象
  window.URL.revokeObjectURL(herf)
}

export const wxConfig = (appId, timestamp, nonceStr, signature, debug = false) => {
  wx.config({
    debug: debug, // 开启调试模式,
    appId: appId, // 必填，企业号的唯一标识
    timestamp: timestamp, // 必填，生成签名的时间戳
    nonceStr: nonceStr, // 必填，生成签名的随机串
    signature: signature, // 必填，签名
    jsApiList: ['scanQRCode', 'checkJsApi'], // 必填，需要使用的JS接口列表
    openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
  });
  wx.ready(() => {
    console.log('配置完成，扫码前准备完成')
  })
  wx.error(function(res) {
    alert('出错了：' + res.errMsg); //wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
  });
}

export const is_andriod_ios = () => {
  var u = navigator.userAgent
  return u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android终端或者uc浏览器
}