<template>
  <div id="app">
    <keep-alive include="sign">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: "App"
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
body {
  font-size: 37.5px !important;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
#app {
  width: 750px;
  height: 100vh;
}
</style>
